export default (() => {

  // accordion
  $('.js-acc_trigger').on('click', function(e){
    const $trigger = $(this);
    const $tgt = $(this).closest('.js-acc').find('.js-acc_tgt');

    // labelがある場合
    let $labelEl, label, chgLabel;
    if ($trigger.attr('aria-label')) {
      $labelEl = $trigger;
    } else if ($trigger.find($('[aria-label]')).length) {
      $labelEl = $trigger.find($('[aria-label]'));
    }

    if ($labelEl) {
      label = $labelEl.attr('aria-label');
      chgLabel = $labelEl.attr('data-chgLabel');
    }

    e.preventDefault();
    $tgt.slideToggle(300);

    // 閉じている場合
    if ($tgt.attr('aria-hidden') === 'true') {
      $trigger.addClass('is-open');
      $tgt.attr('aria-hidden', 'false');
      if($labelEl) {
        $labelEl.attr({'aria-label': chgLabel, 'data-chgLabel': label});
      }
    }
    // 開いている場合
    else {
      $trigger.removeClass('is-open');
      $tgt.attr('aria-hidden', 'true');
      if($labelEl) {
        $labelEl.attr({'aria-label': chgLabel, 'data-chgLabel': label});
      }
    }
  });

})();
