import initValidation from './init_validation.js';
import initForm from './init_form.js';

export default (() => {

  // data属性で指定した要素を表示・非表示
  $(document).on('click', '.js-dispControl', function () {
    const $hideTgt = $('.' + $(this).attr('data-hide'));
    const $showTgt = $('.' + $(this).attr('data-show'));

    $hideTgt.addClass('g-hidden');
    $showTgt.removeClass('g-hidden');

    // 表示切替えエリア内のバリデーションエラーをデフォルト状態に戻す
    const $validation = $hideTgt.find('.m-validation [aria-required="true"], .m-validation.is-checkRequired');
    $validation.each(function() {
      initValidation($(this));
    });

    // 入力したフォームの初期化（ラジオボタン、チェックボックス以外）
    const $formParts = $hideTgt.find('select, input, textarea');
    $formParts.each(function() {
      initForm($(this));
    });
  });


})();
