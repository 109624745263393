import isSp from './is_sp.js';
import tabIndexLoop from '../../../assets/js/main/tabindex_loop.js';

export default (() => {

  // expanded
  // -- トリガーをクリックすることで特定のエリアの開閉を行う動作
  // -- gnav, accordion
  let scrT;
  let pos;

  // ajax処理によってDOM全体が置き換わるため、documentにイベントを設定
  $(document).on('click', '[aria-expanded][aria-controls]:not(".m-gnav_product > a")', function(e){
    const $wrap = $('.m-wrapper');
    const $trigger = $(this);
    const $tgt = $('#' + $trigger.attr('aria-controls'));
    const expanded = $trigger.attr('aria-expanded'); // state

    // labelがある場合
    let $labelEl, label, chgLabel;
    if ($trigger.attr('aria-label')) {
      $labelEl = $trigger;
    } else if ($trigger.find($('[aria-label]')).length) {
      $labelEl = $trigger.find($('[aria-label]'));
    }

    if ($labelEl) {
      label = $labelEl.attr('aria-label');
      chgLabel = $labelEl.attr('data-chgLabel');
    }

    const $triggerText = $trigger.find('.m-triggerText');

    e.preventDefault();

    // 閉じている場合
    if(expanded === 'false') {
      $trigger.attr('aria-expanded', 'true');
      $tgt.attr('aria-hidden', 'false');
      // labelの変更
      if($labelEl) {
        $labelEl.attr({'aria-label': chgLabel, 'data-chgLabel': label});
      }
      // controlのテキスト変更
      if($triggerText) {
        $triggerText.text(chgLabel);
      }
    }

    // 開いている場合
    else {
      $trigger.attr('aria-expanded', 'false');
      $tgt.attr('aria-hidden', 'true');
      // labelの変更
      if($labelEl) {
        $labelEl.attr({'aria-label': chgLabel, 'data-chgLabel': label});
      }
      // controlのテキスト変更
      if($triggerText) {
        $triggerText.text(chgLabel);
      }
    };



    // .js-acc の場合
    // --------------------------------------
    if($trigger.hasClass('js-acc')) {
      $tgt.slideToggle(300);
    }



    // もっと見る（子要素） の場合
    // --------------------------------------
    // 閉じたときに、開いたときのスクロール位置に移動
    if($trigger.hasClass('js-moreChild')) {
      const dispNum = $tgt.attr('data-more');
      const classes = $tgt.attr('class').split(' ');
      let $hiddenChild;
      $.each(classes, (i, cls) => {
        if ($tgt.find('.' + cls + '_item').length) {
          $hiddenChild = $tgt.find('.' + cls + '_item:gt(' + (dispNum - 1) + ')');
        }
      });
      if(expanded === 'false') {
        // 閉じたときのスクロール位置指定がある場合
        if ($(this).is('[data-closepos]')) {
          pos = $('#' + $(this).attr('data-closepos')).offset().top;
        } else {
          pos = $(window).scrollTop();
        }
        $hiddenChild.show();
      } else {
        $hiddenChild.hide();
        $(window).scrollTop(pos);
      }
    }



    // もっと見る（高さ） の場合
    // --------------------------------------
    // 閉じたときに、開いたときのスクロール位置に移動
    if($trigger.hasClass('js-moreHeight')) {
      const dispH = $tgt.attr('data-height');
      const dispHsp = $tgt.attr('data-heightSp');

      if(expanded === 'false') {
        // 閉じたときのスクロール位置指定がある場合
        if ($(this).is('[data-closepos]')) {
          pos = $('#' + $(this).attr('data-closepos')).offset().top;
        } else {
          pos = $(window).scrollTop();
        }
        $tgt.css('height', 'auto');
      }
      else {
        if (isSp()) {
          $tgt.css('height', dispHsp);
        } else {
          $tgt.css('height', dispH);
        }
        $(window).scrollTop(pos);
      }
    }



    // gnav、サイドナビメニュー（SP・タブレット）の場合
    // --------------------------------------
    if($trigger.attr('aria-controls') === 'm-headerSp_menu' || $trigger.attr('aria-controls') === 'm-sidenav_cnt') {
      if(expanded === 'false') {
        scrT = $(window).scrollTop();
        $wrap.addClass('is-fixed').css({ 'top': -1 * scrT });
        // gnav内のタブ移動ループ
        $tgt.tabIndexLoop();
      }
      else {
        closeTgtProcess();
      }

      // escキーで閉じる
      $(window).on('keyup', function(e) {
        if(e.keyCode === 27 && ($trigger.attr('data-esc') === 'true') && ($trigger.attr('aria-expanded') === 'true')) {
          $trigger.attr({'aria-expanded': 'false', 'aria-label': label, 'data-chgLabel': chgLabel});
          $tgt.attr('aria-hidden', 'true');
          $triggerText.text(label);
          $tgt.slideUp(300);
          closeTgtProcess();
        }
      });

      // サイドナビメニュー：閉じるボタンクリック
      $('.m-sidenav_close').on('click', function() {
        closeTgtProcess();
      });

      function closeTgtProcess() {
        $wrap.removeClass('is-fixed').css({ 'top': 0 });
        $(window).scrollTop(scrT);
        // gnav内のタブ移動ループ解除
        $trigger.removeClass('tabloop-trigger').focus();
        $('body').off('keydown.tabindexLoop');
      }
    }

  });

})();
