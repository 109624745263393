export default (() => {

  let swiperPlCol2 = [];
  let swiperPlCol3 = [];
  const $swiperPlCol2 =$('.m-panelLink.swiper-container[data-col="2"]');
  const $swiperPlCol3 =$('.m-panelLink.swiper-container[data-col="3"]');

  // swiper option
  const optionPlCol2 = {
    autoplay: {
      delay: 5000
    },
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
    speed: 500,
    calculateHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: { el: '.swiper-pagination' },
    keyboard: { enabled: true },
    slideToClickedSlide: true,
    resizeReInit: true,
    observer: true,
    observeParents: true,
    breakpoints: {
      769: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 30
      }
    }
  };
  const optionPlCol3 = {
    autoplay: {
      delay: 5000
    },
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
    speed: 500,
    calculateHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: { el: '.swiper-pagination' },
    keyboard: { enabled: true },
    slideToClickedSlide: true,
    observer: true,
    observeParents: true,
    observer: true,
    observeParents: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 30
      }
    }
  }


  $swiperPlCol2.each(function(i) {
    const _this = $(this);
    swiperPlCol2 = new Swiper($swiperPlCol2, optionPlCol2);
    pagenationCheck(_this);
    $(window).on('resize', function() {
      setTimeout(function() {
        pagenationCheck(_this);
      }, 100);
    });
  });

  $swiperPlCol3.each(function(i) {
    const _this = $(this);
    swiperPlCol3 = new Swiper($swiperPlCol3, optionPlCol3);
    pagenationCheck(_this);
    $(window).on('resize', function() {
      setTimeout(function() {
        pagenationCheck(_this);
      }, 100);
    });
  });

  // ページネーションのbulletsが1つしかない場合は非表示にする
  function pagenationCheck(_this) {
    const $pagenation = _this.find('.swiper-pagination-bullets');
    const $bullet = $pagenation.find('.swiper-pagination-bullet');

    if (0 < $bullet.length && $bullet.length < 2) {
      $pagenation.hide();
    } else if (1 < $bullet.length) {
      $pagenation.show();
    }
  }


})();
