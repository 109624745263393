import isSp from '../../../assets/js/main/is_sp.js';

export default (() => {

  // search suggest
  $.extend({
    // 検索サジェスト表示
    suggestShow: function() {
      if (isSp()) {
        $(window).scrollTop(0);
      }
      $('html').addClass('is-suggestShow');
      $('.m-suggest').attr('aria-hidden', 'false');
    },

    // 検索サジェスト非表示
    suggestHide: function() {
      $('.m-suggest').attr('aria-hidden', 'true');
      $('html').removeClass('is-suggestShow');

    }
  });


  $('.m-suggest_bg').on('click', function() {
    $.suggestHide();
  });

})();
