import isSp from './is_sp.js';

export default (() => {

  // 1カラムの場合、PC時の画像をボックスの上下中央に表示す
  $('.m-relatedLink').each(function() {
    const $panel = $(this);
    const $img = $panel.find('.m-relatedLink_img img');
    let imgH;
    let panelH;

    $(window).on('load resize', function() {
      if (!isSp()) {
        imgH = $img.height();
        panelH = $panel.height();
        if (panelH < imgH) {
          let topPos = 0 - ((imgH - panelH) /2);
          $img.css('margin-top', topPos);
        } else {
          $img.css('margin-top', 0);
        }
      };
    });
  });

})();
