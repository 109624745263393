// FAQ feedback
export default (() => {

  const $feedback = $('.m-feedback');
  const $answer = $feedback.find('.m-feedback_answer');
  const $thanks = $feedback.find('.m-feedback_thanks');
  const $enquete = $feedback.find('.m-feedback_enquete');
  const $textarea = $enquete.find('textarea');
  const $submit = $enquete.find('.m-formSubmit');
  const $send = $feedback.find('.m-feedback_enqueteSend');
  const $yes = $feedback.find('.m-feedbackYes');
  const $no = $feedback.find('.m-feedbackNo');


  // はい
  $yes.on('click', function() {
    $answer.hide();
    $thanks.fadeIn(200);
  });

  // いいえ
  $no.on('click', function() {
    $answer.hide();
    $thanks.fadeIn(200);
    $enquete.fadeIn(200);
  });

  // 送信
  // $submit.on('click', function() {
  //   $enquete.hide();
  //   $send.fadeIn(200);
  // });

  // 送信ボタン活性化
  $textarea.on('keyup mouseup blur', function() {
    let value = $(this).val();
    const check = /^[\s　]+$/;
    if (value !== '' && !check.test(value)) {
      $submit.prop('disabled', false);
    } else {
      $submit.prop('disabled', true);
    }
  });


})();
