export default (() => {

  $.productGallery = function() {
    const productGalleryThumbs = new Swiper('.m-productGallery_thumb.swiper-container', {
      spaceBetween: 10,
      slidesPerView: 6,
      breakpoints: {
        769: {
          spaceBetween: 20
        }
      }
    });

    const productGalleryMain = new Swiper('.m-productGallery_main.swiper-container', {
      spaceBetween: 0,
      effect: 'fade',
      thumbs: {
        swiper: productGalleryThumbs
      }
    });
  };

  $.productGallery();

})();
