export default (() => {

  // youtube
  const $faqYoutube = $('.m-faqCnt iframe[src*="youtube"]');
  const $newsYoutube = $('.m-richEditor iframe[src*="youtube"]');
  if ($faqYoutube.length) {
    $faqYoutube.wrap('<div class="m-faqCnt_youtube"></div>');
  } else if ($newsYoutube.length) {
    $newsYoutube.wrap('<div class="m-richEditor_youtube"></div>');
  }

  // table
  const $faqTable = $('.m-faqCnt table:not(.sp-block)');
  const $newsTable = $('.m-richEditor table:not(.sp-block)');
  if ($faqTable.length) {
    $faqTable.wrap('<div class="m-faqCnt_table"></div>');
  } else if ($newsTable.length) {
    $newsTable.wrap('<div class="m-richEditor_table"></div>');
  }

})();
