export default (() => {

  // Geocoking: 郵便番号から住所を検索
  $('.js-addSearch').on('click', function() {
    const _this = $(this);
    const $parent = _this.closest('.m-addGroup');
    const $zipcode = $parent.find('.m-addGroup_zipcode');
    const zipcode = $zipcode.val();
    const $btnReEnter = $parent.find('.js-reEnter');
    const $prefecture = $parent.find('.m-addGroup_prefecture');
    const $city = $parent.find('.m-addGroup_city');
    const $address = $parent.find('.m-addGroup_address');
    const $autoInput = $parent.find('.m-addGroup_auto, .m-addGroup_address');

    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({'address': zipcode}, function(results, status) {
      if (status === google.maps.GeocoderStatus.OK) {

        // 結果が2つ以上返ってくるレアケースがあるが、1つ目候補をセットする
        const objects = results[0].address_components;
        let prefecture = '';
        let locality = '';
        let postalCode = '';
        let adminarea = [];
        let address = [];

        // 前の値をクリア、バリデーションチェックもクリア
        $autoInput.each(function() {
          $(this).val('').closest('.m-validation').removeAttr('data-valid');
        });

        $.each(objects, function(i, obj) {

          // 郵便番号を取得
          if (obj['types'].indexOf('postal_code') !== -1) {
            postalCode = obj['long_name'].replace('-', '');
          }
          // 都道府県を取得
          if (obj['types'].indexOf('administrative_area_level_1') !== -1) {
            prefecture = obj['long_name'];
          }
          // locality を取得
          else if (obj['types'].indexOf('locality') !== -1) {
            locality = obj['long_name'];
          }
          // administrative_area_level_2〜5 を取得
          for (let j = 2; j < 6; j++) {
            if (obj['types'].indexOf('administrative_area_level_' + j) !== -1) {
              adminarea.unshift(obj['long_name']);
            }
          }
          // sublocality_level_1〜5 を取得
          for (let k = 1; k < 6; k++) {
            if (obj['types'].indexOf('sublocality_level_' + k) !== -1) {
              address.unshift(obj['long_name']);
            }
          }
        });

        // 都道府県の値がない場合、郵便番号が一致しない場合はエラー表示
        if (prefecture === '' || postalCode !== zipcode) {
          $('#modalNoAddress').modalCreate();
          return false;
        } else {
          // 都道府県をセット
          $prefecture.val(prefecture);
        }

        // administrative_area_level_2〜5 がある場合
        if (adminarea.length > 0) {
          $city.val(adminarea.join(' '));
          address.unshift(locality);
          $address.val(address.join(' '));
        }
        // administrative_area_level_2〜5 がない場合
        else {
          $city.val(locality);
          $address.val(address.join(' '));
        }


        // 郵便番号フィールドを readonly にして、住所検索ボタンを再入力ボタンに変更する
        if ($prefecture.val() !== '' && $city.val() !== '') {
          $zipcode.attr('readonly', 'readonly');
          _this.addClass('g-hidden');
          $btnReEnter.removeClass('g-hidden');
        }


        // バリデーションチェック
        $autoInput.each(function() {
          const val = $(this).val();
          const $msgs = $('#' + $(this).attr('aria-describedby')).find('[data-error], .errorMsg');

          if (val !== '') {
            $(this).closest('.m-validation').attr('data-valid', true);
            $(this).removeAttr('aria-invalid');
            $msgs.hide();
          }
        });
        // サブミットボタン活性化チェック
        $prefecture.focus().blur();
      }


      //- ZERO_RESULTS: データ取得失敗
      else {
        $('#modalNoAddress').modalCreate();
      }
    });
  });

})();





