export default (() => {

  // list expanded
  $('.js-listExpand_switch').on('change click', function() {
    const $tgt = $(this).closest('li').find('.js-listExpand_tgt');
    const $siblings = $(this).closest('li').siblings('li').find('.js-listExpand_tgt');

    if ($(this).prop('tagName') === 'INPUT') {
      if ($(this).prop('checked')) {
        $siblings.addClass('g-hidden');
        $tgt.removeClass('g-hidden');
      }
    } else {
      $siblings.addClass('g-hidden');
      $tgt.removeClass('g-hidden');
    }
  });


})();
