import { auto } from "async";

export default (() => {

  // トップメインビジュアル
  const $topSwiper =$('.m-topMv .swiper-container');
  const optionTop = {
    loop: true,
    // loopedSlides: 3,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    centeredSlides: true,
    speed: 800,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: { el: '.swiper-pagination' },
  }

  let swiperTop = new Swiper($topSwiper, optionTop);

})();
