// pagetop
export default (() => {

  const $win = $(window);
  const $footer = $('.m-fbrand');
  const $pagetop = $('.m-pagetop');
  const cls = 'is-btm';
  let startPos = 0;
  let currentPos = 0;
  let stopTimeout;

  $win.on('scroll', function() {
    currentPos = $(this).scrollTop();
    if (currentPos > $win.height()) {
      clearTimeout(stopTimeout);

      // 上スクロールの場合
      if (currentPos < startPos) {
        let amount = startPos - currentPos;
        if (amount > 100) {
          $pagetop.fadeOut(100);
        }
      }
      // 下スクロールの場合
      else {
        $pagetop.fadeOut(100);
      }
      startPos = currentPos;

      // スクロール停止
      stopTimeout = setTimeout(function() {
        $pagetop.fadeIn(300);
      }, 500);

      // 下まで来たらフッター上に固定
      const scrollBtm = $win.scrollTop() + $win.height();
      const footerTop = $footer.offset().top;
      if($footer.length > 0){
        if (scrollBtm >= footerTop) {
          $pagetop.addClass(cls).css('top', footerTop);
        } else {
          $pagetop.removeClass(cls).css('top', 'auto');
        }
      }
    }
    // ファーストビューでは非表示
    else {
      $pagetop.fadeOut(100);
    }
  });

})();
