export default (() => {

  // タブ
  // 商品詳細ページにおいて　ajax処理によってDOM全体が置き換わるため、documentにイベントを設定
  $(document).on('click', '[role="tab"]', function(e) {
    e.preventDefault();

    let $tab = $(this);
    const tgtPanel = $tab.attr('aria-controls');

    // タブ切り替えリンク
    if ($(this).closest('.m-tabBtm').length) {
      $tab = $(this).closest('.m-tab').find('.m-tab_list [aria-controls="' + tgtPanel + '"]');
      let scrT = $tab.closest('.m-tab').offset().top;
      if ($(this).closest('.m-pointSection').length) {
        scrT =$(this).closest('.m-pointSection').offset().top;
      }
      $(window).scrollTop(scrT);
    }

    if ($tab.attr('aria-selected') === 'true') {
      return false;
    }
    else {
      activateTab($tab, tgtPanel);
    }
    return false;
  });

  // 初期表示（JS Off時の対応）
  $(function() {
    $('[role="tabpanel"][aria-hidden="true"]').hide();
  });


  // 左右の矢印キーでのタブ選択
  $(window).on('keyup', function(e) {
    const _this = $(document.activeElement);

    if (_this.is('[role="tab"]')) {
      switch (e.keyCode) {
        case 37:
          prev(_this);
          break;
        case 39:
          next(_this);
          break;
      }

      function prev(_this) {
        const $parent = _this.parent('li');
        const $tab = $parent.prev('li').find('[role="tab"]')
        const tgtPanel = $tab.attr('aria-controls');
        const index = $parent.index();

        if (index !== 0) {
          activateTab($tab, tgtPanel);
        }
      }

      function next(_this) {
        const $parent = _this.parent('li');
        const $tab = $parent.next('li').find('[role="tab"]')
        const tgtPanel = $tab.attr('aria-controls');
        const len = $parent.siblings().length;
        const index = $parent.index();

        if (index !== len) {
          activateTab($tab, tgtPanel);
        }
      }
    }
  });

  // タブとパネルのアクティブ化
  function activateTab (tab, panel) {
    const $tab = tab;
    const tgtPanel = panel;
    const $tabContainer = $tab.closest('.m-tab');
    const $tabList = $tab.closest('[role="tablist"]');
    const $tabs = $tabList.find('[role="tab"]');
    const $panels = $tabContainer.find('[role="tabpanel"]');

    $tabs.attr({'aria-selected': 'false', 'tabindex': '-1'});
    $tab.attr({'aria-selected': 'true', 'tabindex': '0'}).focus();
    $panels.attr('aria-hidden', 'true').hide();
    $('#' + tgtPanel).attr('aria-hidden', 'false').show();
  }


})();
