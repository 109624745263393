// follow area
export default (() => {

  $.fn.followArea = function() {
    if ($('.js-followBase').length) {
      const $follow = $(this);
      const $base = $('.js-followBase');
      const baseBtm = $base.offset().top + $base.outerHeight();
      const $pagetop = $('.m-pagetop');
      const ptB = parseInt($pagetop.css('bottom'));
      let followH;
      let currentPos;

      $(window).on('scroll resize', function() {
        currentPos = $(window).scrollTop();
        if (currentPos >= baseBtm) {
          $follow.fadeIn(200);
          // スティッキーが下にある場合、ページの先頭へリンクをその上に表示する
          if ($follow.css('bottom') === '0px') {
            followH = $follow.outerHeight();
            $pagetop.css('bottom', followH + ptB);
          }
        } else {
          $follow.fadeOut(200);
          if ($follow.css('bottom') === '0px') {
            $pagetop.css('bottom', ptB);
          }
        }
      });
    }
  }

  $('.js-followArea').followArea();


})();
