export default (() => {

  // goodslist view
  const $control = $('.m-controlbar_view input');
  const $tgt = $('.m-goodslist[data-view]');

  $control.on('change', function() {
    let val = $(this).val();
    $tgt.attr('data-view', val);
  });

})();
