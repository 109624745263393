export default (() => {

  // フォーム要素の排他制御
  $('.js-exclusive_item').on('change', function() {
    exclusiveItem($(this));
  });

  $(window).on('load', function() {
    $('.js-exclusive_item').each(function() {
      exclusiveItem($(this));
    });
  });


  function exclusiveItem(_this) {
    const $parent = _this.closest('.js-exclusive');
    let $others = $parent.find('.js-exclusive_item').not(_this);
    const thisType = _this.attr('type');
    const check = ['checkbox', 'radio'];

    if ((check.includes(thisType) && _this.prop('checked') === true) || (!check.includes(thisType) && _this.val() !== '')) {
      $others.prop('disabled', true);
    } else {
      $others.prop('disabled', false);
    }
  }


})();
