export default (() => {

  // loading
  $.extend({
    // ローディング表示
    loadingShow: function() {
      if ($('.m-loading').length === 0) {
        $('body').append('<div class="m-loading"><div class="m-loading_inner"><div class="ball-spin-fade-loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></div>');
      }
      $('html').addClass('is-loadingShow');
      $('.m-loading').fadeIn(150);
    },

    // ローディング非表示
    loadingHide: function() {
      $('.m-loading').fadeOut(150);
      $('html').removeClass('is-loadingShow');
    }
  });

})();


