export default (() => {

  // もっと見る
  // 指定の表示件数以上の場合はもっと見るボタンを表示する。以下の場合は非表示にする。
  // 子要素のクラス名が {{親要素クラス名}}_item であるものが対象
  $.fn.moreChild = function() {
    $(this).each(function() {
      const $more = $(this);
      const $tgt = $('#' + $more.attr('aria-controls'));
      const dispNum = $tgt.attr('data-more');
      const classes = $tgt.attr('class').split(' ');
      let $hiddenChild;

      $.each(classes, (i, cls) => {
        if ($tgt.find('.' + cls + '_item').length) {
          const $child = $tgt.find('.' + cls + '_item');
          const childNum = $child.length;
          $hiddenChild = $tgt.find('.' + cls + '_item:gt(' + (dispNum - 1) + ')');

          if (childNum <= dispNum) {
            $more.hide();
            $tgt.attr('aria-hidden', false);
          } else {
            $more.show();
            $hiddenChild.hide();
            $tgt.attr('aria-hidden', true);
          }
        }
      });

      // モーダル内の場合、モーダルを閉じた時に初期状態に戻す
      if($more.closest('.m-modal').length) {
        const label = $more.attr('aria-label');
        const chgLabel = $more.attr('data-chgLabel');
        $more.closest('.m-modal').find('.m-modal_close, .m-modal_closeBtn, .m-modal_closeItem, .m-modal_bg').on('click', function() {
          $hiddenChild.hide();
          $tgt.attr('aria-hidden', true);
          $more.attr('aria-expanded', false);
          $more.attr({'aria-label': label, 'data-chgLabel': chgLabel});
          $more.find('.m-triggerText').text(label);
        });
      }
    });
  }

  $('.js-moreChild').moreChild();


})();
