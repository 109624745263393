export default (() => {

  const $swiper =$('.m-glSpecial.swiper-container');
  const optionGl = {
    loop: true,
    autoplay: {
      delay: 5000
    },
    slidesPerView: 2,
    slidesPerGroup: 1,
    centeredSlides: true,
    speed: 500,
    calculateHeight: true,
    resizeReInit: true,
    observer: true,
    observeParents: true
  }

  let swiperGl = new Swiper($swiper, optionGl);

})();
