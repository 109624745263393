// form select other
export default (() => {

  // その他選択時の入力フィールド制御
  $('.js-selectOther').each(function() {
    const _this = $(this);

    $(this).on('change', function() {
      otherCtl($(this));
    });

    // 確認画面から「修正する」で戻った場合
    $(window).on('load', function() {
      otherCtl(_this);
    });

    function otherCtl(_this) {
      const $input = _this.closest('.js-otherControl').find('.' + _this.attr('data-controls'));
      const $errMsg = $('#' + $input.attr('aria-describedby'));

      if (_this.find('option:selected').is('[data-select="other"]')) {
        $input.prop('disabled', false).attr('aria-required', true);
      } else {
        $input.closest('.m-validation').removeAttr('data-valid');
        $input.prop('disabled', true).removeAttr('aria-invalid aria-required').val('');
        $errMsg.find('.m-formError').hide();
      }
    }
  });


})();
