// search
export default (() => {

  const $search = $('.m-searchValidation');
  const $keyword = $search.find('input[type="text"]');
  const $submit = $search.closest('form').find('button');
  const $msgs = $('#' + $keyword.attr('aria-describedby')).find('[data-error]');
  let value;

  if ($search.length) {
    $keyword.on('keyup mouseup blur', function() {
      value = $keyword.val().trim();
      if (value.length > 1) {
        $keyword.attr('aria-invalid', false);
        $msgs.hide();
        $submit.prop('disabled', false);
      }
    });

    $keyword.on('blur', function() {
      value = $keyword.val().trim();
      const space = /^[\s　]+$/;
      if ((value !== '' && value.length < 2) || space.test($keyword.val())) {
        $keyword.attr('aria-invalid', true);
        $msgs.show();
        $submit.prop('disabled', true);
      }
      else if (value === '') {
        $keyword.attr('aria-invalid', false);
        $msgs.hide();
        $submit.prop('disabled', true);
      }
    });
  }


})();
