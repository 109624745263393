export default (() => {

  // フォーム要素の連動制御
  $('.js-linkedRequired').each(function() {
    const $trigger = $(this).find('.js-linkedRequired_trigger');
    const $item = $(this).find('.js-linkedRequired_item');

    $trigger.on('change', function() {
      const triggerVal = $(this).val();
      linkedRequired(triggerVal);
    });

    // 確認画面から「修正する」で戻った場合
    $(window).on('load', function() {
      const triggerVal = $trigger.val();
      linkedRequired(triggerVal);
    });


    function linkedRequired(triggerVal) {
      $item.each(function() {
        const $required = $(this).closest('tr').find('.m-formTtl_required');
        const $errMsg = $('#' + $(this).attr('aria-describedby')).find('[data-error], .errorMsg');

        if (triggerVal !== '') {
          $(this).prop('disabled', false).prop('required', true).attr('aria-required', true);
          $required.removeClass('g-hidden');
        }
        else {
          $(this).prop('disabled', true).removeAttr('aria-invalid').val('');
          $(this).closest('.m-validation').removeAttr('data-valid required aria-required');
          $errMsg.hide();
          $required.addClass('g-hidden');
        }
      });
    }
  });


})();
