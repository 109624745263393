import isSp from './is_sp.js';

export default (() => {

  const $swiper =$('.m-spcnt_mainimg .swiper-container');
  const optionSp = {
    autoplay: {
      delay: 5000
    },
    slidesPerView: 1,
    slidesPerGroup: 1,
    speed: 500,
    calculateHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: { el: '.swiper-pagination' },
    keyboard: { enabled: true },
    slideToClickedSlide: true,
    resizeReInit: true,
    observer: true,
    observeParents: true
  }

  let swiperSp = new Swiper($swiper, optionSp);


  // セットスライダーの場合：ページネーションの位置セット
  const $setSlider = $('.m-spcnt_mainimg-setSlider');
  const $pagination = $setSlider.find('.swiper-pagination');
  const $btns = $setSlider.find('.swiper-button-next, .swiper-button-prev');
  const $mainimg = $setSlider.find('.swiper-slide picture img');
  let timer = false;
  let currentW = window.innerWidth ? window.innerWidth : $(window).width();


  let imgH = $mainimg.height();
  $pagination.css('top', imgH);
  if (isSp()) {
    $btns.css('top', (imgH + 35));
  } else {
    $btns.css('top', (imgH/2));
  }

  $(window).on('resize', function() {
    // for SP devices
    let resizeW = window.innerWidth ? window.innerWidth : $(window).width();
    if (currentW === resizeW) { return; } else { currentW = resizeW; }

    if (timer !== false) clearTimeout(timer);
    timer = setTimeout(function() {
      imgH = $mainimg.height();
      $pagination.css('top', imgH);
      if (isSp()) {
        $btns.css('top', (imgH + 35));
      } else {
        $btns.css('top', (imgH/2));
      }
    }, 500);

  });


})();
