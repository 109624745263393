export default (() => {

  // カート投入メッセージを表示
  $.fn.addCartMsg = function() {
    const ACTIVE = 'is-active';
    const $msgbtn = $(this);
    const $msg = $msgbtn.closest('.m-addCart').find('.m-addCart_msg');
    $msgbtn.blur();

    if(!$msg.hasClass(ACTIVE)) {
      $msg.addClass(ACTIVE);
      setTimeout(function(){
        $msg.attr('aria-hidden', 'false');
      }, 100);
      setTimeout(function(){
        $msg.attr('aria-hidden', 'true');
      }, 5000);
      setTimeout(function(){
        $msg.removeClass(ACTIVE);
      }, 5200);
    }
  }

})();
