import topMv from '../../assets/js/main/top_mv.js';
import intersectionObserver from '../../assets/js/main/intersection_observer.js';
import expanded from '../../assets/js/main/expanded.js';
import accordion from '../../assets/js/main/accordion.js';
import expandControl from '../../assets/js/main/expand_control.js';
import displayControl from '../../assets/js/main/display_control.js';
import displayMoreChild from '../../assets/js/main/display_more_child.js';
import displayMoreHeight from '../../assets/js/main/display_more_height.js';
import menuPanel from '../../assets/js/main/menu_panel.js';
import pnav from '../../assets/js/main/pnav.js';
import sidenav from '../../assets/js/main/sidenav.js';
import breadcrumb from '../../assets/js/main/breadcrumb.js';
import anchorScroll from '../../assets/js/main/anchor_scroll.js';
import pagetop from '../../assets/js/main/pagetop.js';
import modal from '../../assets/js/main/modal.js';
import tab from '../../assets/js/main/tab.js';
import alignHeight from '../../assets/js/main/align_height.js';
import goodslistView from '../../assets/js/main/goodslist_view.js';
import goodslist from '../../assets/js/main/goodslist.js';
import goodslistColor from '../../assets/js/main/goodslist_color.js';
import panelLink from '../../assets/js/main/panel_link.js';
import relatedLinkImg from '../../assets/js/main/related_link_img.js';
import glSpecial from '../../assets/js/main/gl_special.js';
import mainVisual from '../../assets/js/main/main_visual.js';
import specialSlider from '../../assets/js/main/special_slider.js';
import productGallery from '../../assets/js/main/product_gallery.js';
import tabIndexLoop from '../../assets/js/main/tabindex_loop.js';
import followArea from '../../assets/js/main/follow_area.js';
import followClose from '../../assets/js/main/follow_close.js';
import formPwShow from '../../assets/js/main/form_pw_show.js';
import formExclusive from '../../assets/js/main/form_exclusive.js';
import formLinkedRequired from '../../assets/js/main/form_linked_required.js';
import formSelectOther from '../../assets/js/main/form_select_other.js';
import fromRegisterArea from '../../assets/js/main/form_register_area.js';
import fromListExpand from '../../assets/js/main/form_list_expand.js';
import formValidation from '../../assets/js/main/form_validation.js';
import tooltip from '../../assets/js/main/tooltip.js';
import addBookmark from '../../assets/js/main/add_bookmark.js';
import cartSpinner from '../../assets/js/main/cart_spinner.js';
import productCv from '../../assets/js/main/product_cv.js';
import addressSearchApi from '../../assets/js/main/address_search_api.js';
import addressSearch from '../../assets/js/main/address_search.js';
import richEditor from '../../assets/js/main/rich_editor.js';
import faqFeedback from '../../assets/js/main/faq_feedback.js';
import search from '../../assets/js/main/search.js';
import loading from '../../assets/js/main/loading.js';
import searchSuggest from '../../assets/js/main/search_suggest.js';
import addCart from '../../assets/js/main/add_cart.js';
import snsShare from '../../assets/js/main/sns_share.js';
import cookieCheck from '../../assets/js/main/cookie_check.js';
import windowClose from '../../assets/js/main/window_close.js';

// temporary script
import temp from '../../assets/js/main/_temp.js';
