import isSp from './is_sp.js';

export default (() => {

  let scrT;
  let $hidden;
  let swiperGlCol2 = [];
  let swiperGlCol4 = [];
  let swiperGlCol5 = [];
  let swiperGlCol6 = [];
  let swiperGlCol8 = [];
  const goodslistCol2 = '.m-goodslist.swiper-container[data-col="2"]';
  const goodslistCol4 = '.m-goodslist.swiper-container[data-col="4"]';
  const goodslistCol5 = '.m-goodslist.swiper-container[data-col="5"]';
  const goodslistCol6 = '.m-goodslist.swiper-container[data-col="6"]';
  const goodslistCol8 = '.m-goodslist.swiper-container[data-col="8"]';

  // swiper option
  const optionGlCol2 = {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 40,
    speed: 500,
    calculateHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: { enabled: true },
    slideToClickedSlide: true,
    resizeReInit: true,
    observer: true,
    observeParents: true
  }
  const optionGlCol4 = {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 40,
    speed: 500,
    calculateHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: { enabled: true },
    slideToClickedSlide: true,
    resizeReInit: true,
    observer: true,
    observeParents: true
  }
  const optionGlCol5 = {
    slidesPerView: 5,
    slidesPerGroup: 5,
    spaceBetween: 30,
    speed: 500,
    calculateHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: { enabled: true },
    slideToClickedSlide: true,
    resizeReInit: true,
    observer: true,
    observeParents: true
  }
  const optionGlCol6 = {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 20,
    speed: 500,
    calculateHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: { enabled: true },
    slideToClickedSlide: true,
    resizeReInit: true,
    observer: true,
    observeParents: true,
    breakpoints: {
      961: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 6,
        slidesPerGroup: 6,
      }
    }
  }
  const optionGlCol8 = {
    slidesPerView: 5,
    slidesPerGroup: 5,
    spaceBetween: 20,
    speed: 500,
    calculateHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: { enabled: true },
    slideToClickedSlide: true,
    resizeReInit: true,
    observer: true,
    observeParents: true,
    breakpoints: {
      961: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 8,
        slidesPerGroup: 8,
      }
    }
  }


  // 商品リスト
  $.fn.goodslist = function() {
    $(this).each(function() {
      const _this = $(this);
      let timer = false;
      let currentW = window.innerWidth ? window.innerWidth : $(window).width();

      $(window).on('load', function() {
        itemMore(_this);
        itemHeight(_this);
        priceWidth(_this);
      });

      $(window).on('resize', function() {
        // for SP devices
        let resizeW = window.innerWidth ? window.innerWidth : $(window).width();
        if (currentW === resizeW) { return; } else { currentW = resizeW; }

        if (timer !== false) clearTimeout(timer);
        timer = setTimeout(function() {
          itemMore(_this);
          itemHeight(_this);
          priceWidth(_this);
        }, 100);
      });

      // 商品一覧の表示切り替え時
      $('.m-controlbar_view input').on('change', function() {
        itemHeight(_this);
        priceWidth(_this);
      });
      // タブ切り替え時
      // 商品詳細ページにおいて　ajax処理によってDOM全体が置き換わるため、documentにイベントを設定
      $(document).on('click', '[role="tab"]', function(e) {
        itemHeight(_this);
        priceWidth(_this);
      });
    });
  }


  // ajax処理によるDOM切り替え後に呼び出すメソッド
  $.fn.initGoodslist = function() {
    $(this).each(function() {
      itemMore($(this));
      itemHeight($(this));
      priceWidth($(this));
    });
  }





  $.extend({
    // goodslist: col2
    goodslistCol2: function() {
      $(goodslistCol2).each(function(i) {
        const _this = $(this);
        let timer = false;
        let currentW = window.innerWidth ? window.innerWidth : $(window).width();

        swiperGlCol2 = undefined;
        initSwiperGlCol2(_this, i);

        $(window).on('resize', function() {
          // for SP devices
          let resizeW = window.innerWidth ? window.innerWidth : $(window).width();
          if (currentW === resizeW) { return; } else { currentW = resizeW; }

          if (timer !== false) clearTimeout(timer);
          timer = setTimeout(function() {
            initSwiperGlCol2(_this, i);
          }, 500);
        });

        function initSwiperGlCol2(_this, i) {
          const $wrapper = _this.find('.swiper-wrapper');
          const $slide = _this.find('.swiper-slide');

          if (!isSp() && swiperGlCol2 === undefined) {
            swiperGlCol2 = new Swiper(goodslistCol2, optionGlCol2);
            swiperGlCol2 = $.makeArray(swiperGlCol2);
          }
          else if (isSp() && swiperGlCol2 !== undefined) {
            $.each(swiperGlCol2, function() {
              swiperGlCol2[i].destroy();
            });
            swiperGlCol2 = undefined;
            $wrapper.removeAttr('style');
            $slide.removeAttr('style');
            itemMore(_this);
          }

          if (!isSp()) {
            itemHeight(_this);
            swiperGlCol2[i].update();
            swiperGlCol2[i].navigation.update();
          }
        }
      });
    },

    // goodslist: col4
    goodslistCol4: function() {
      $(goodslistCol4).each(function(i) {
        const _this = $(this);
        let timer = false;
        let currentW = window.innerWidth ? window.innerWidth : $(window).width();

        swiperGlCol4 = undefined;
        initSwiperGlCol4(_this, i);

        $(window).on('resize', function() {
          // for SP devices
          let resizeW = window.innerWidth ? window.innerWidth : $(window).width();
          if (currentW === resizeW) { return; } else { currentW = resizeW; }

          if (timer !== false) clearTimeout(timer);
          timer = setTimeout(function() {
            initSwiperGlCol4(_this, i);
          }, 500);
        });

        function initSwiperGlCol4(_this, i) {
          const $wrapper = _this.find('.swiper-wrapper');
          const $slide = _this.find('.swiper-slide');

          if (!isSp() && swiperGlCol4 === undefined) {
            swiperGlCol4 = new Swiper(goodslistCol4, optionGlCol4);
            swiperGlCol4 = $.makeArray(swiperGlCol4);
          }
          else if (isSp() && swiperGlCol4 !== undefined) {
            $.each(swiperGlCol4, function() {
              swiperGlCol4[i].destroy();
            });
            swiperGlCol4 = undefined;
            $wrapper.removeAttr('style');
            $slide.removeAttr('style');
            itemMore(_this);
          }

          if (!isSp()) {
            itemHeight(_this);
            swiperGlCol4[i].update();
            swiperGlCol4[i].navigation.update();
          }
        }
      });
    },

    // goodslist: col5
    goodslistCol5: function() {
      $(goodslistCol5).each(function(i) {
        const _this = $(this);
        let timer = false;
        let currentW = window.innerWidth ? window.innerWidth : $(window).width();

        swiperGlCol5 = undefined;
        initSwiperGlCol5(_this, i);

        $(window).on('resize', function() {
          // for SP devices
          let resizeW = window.innerWidth ? window.innerWidth : $(window).width();
          if (currentW === resizeW) { return; } else { currentW = resizeW; }

          if (timer !== false) clearTimeout(timer);
          timer = setTimeout(function() {
            initSwiperGlCol5(_this, i);
          }, 500);
        });

        function initSwiperGlCol5(_this, i) {
          const $wrapper = _this.find('.swiper-wrapper');
          const $slide = _this.find('.swiper-slide');

          if (!isSp() && swiperGlCol5 === undefined) {
            swiperGlCol5 = new Swiper(goodslistCol5, optionGlCol5);
            swiperGlCol5 = $.makeArray(swiperGlCol5);
          }
          else if (isSp() && swiperGlCol5 !== undefined) {
            $.each(swiperGlCol5, function() {
              swiperGlCol5[i].destroy();
            });
            swiperGlCol5 = undefined;
            $wrapper.removeAttr('style');
            $slide.removeAttr('style');
            itemMore(_this);
          }

          if (!isSp()) {
            itemHeight(_this);
            swiperGlCol5[i].update();
            swiperGlCol5[i].navigation.update();
          }
        }
      });
    },

    // goodslist: col6 (toppage)
    goodslistCol6: function() {
      $(goodslistCol6).each(function(i) {
        const _this = $(this);
        let timer = false;
        let currentW = window.innerWidth ? window.innerWidth : $(window).width();

        colChange(currentW);
        swiperGlCol6 = undefined;
        initSwiperGlCol6(_this, i);

        $(window).on('resize', function() {
          // for SP devices
          let resizeW = window.innerWidth ? window.innerWidth : $(window).width();
          if (currentW === resizeW) { return; } else { currentW = resizeW; }

          colChange(resizeW);

          if (timer !== false) clearTimeout(timer);
          timer = setTimeout(function() {
            initSwiperGlCol6(_this, i);
          }, 500);
        });


        function colChange(w) {
          // カラム数変更
          if (w >= 1200) {
            _this.attr('data-col', '6');
          }
          else if (w < 1200 && w > 960) {
            _this.attr('data-col', '5');
          }
          else if (w < 961) {
            _this.attr('data-col', '4');
          }
        }

        function initSwiperGlCol6(_this, i) {
          const $wrapper = _this.find('.swiper-wrapper');
          const $slide = _this.find('.swiper-slide');

          if (!isSp() && swiperGlCol6 === undefined) {
            swiperGlCol6 = new Swiper(goodslistCol6, optionGlCol6);
            swiperGlCol6 = $.makeArray(swiperGlCol6);
          }
          else if (isSp() && swiperGlCol6 !== undefined) {
            $.each(swiperGlCol6, function() {
              swiperGlCol6[i].destroy();
            });
            swiperGlCol6 = undefined;
            $wrapper.removeAttr('style');
            $slide.removeAttr('style');
            itemMore(_this);
          }
          if (!isSp()) {
            itemHeight(_this);
            swiperGlCol6[i].update();
            swiperGlCol6[i].navigation.update();
          }
        }
      });
    },

    // goodslist: col8 (toppage)
    goodslistCol8: function() {
      $(goodslistCol8).each(function(i) {
        const _this = $(this);
        let timer = false;
        let currentW = window.innerWidth ? window.innerWidth : $(window).width();

        swiperGlCol8 = undefined;
        initSwiperGlCol8(_this, i);

        $(window).on('resize', function() {
          // for SP devices
          let resizeW = window.innerWidth ? window.innerWidth : $(window).width();
          if (currentW === resizeW) { return; } else { currentW = resizeW; }

          if (timer !== false) clearTimeout(timer);
          timer = setTimeout(function() {
            initSwiperGlCol8(_this, i);
          }, 500);
        });

        function initSwiperGlCol8(_this, i) {
          const $wrapper = _this.find('.swiper-wrapper');
          const $slide = _this.find('.swiper-slide');

          if (!isSp() && swiperGlCol8 === undefined) {
            swiperGlCol8 = new Swiper(goodslistCol8, optionGlCol8);
            swiperGlCol8 = $.makeArray(swiperGlCol8);
          }
          else if (isSp() && swiperGlCol8 !== undefined) {
            $.each(swiperGlCol8, function() {
              swiperGlCol8[i].destroy();
            });
            swiperGlCol8 = undefined;
            $wrapper.removeAttr('style');
            $slide.removeAttr('style');
            itemMore(_this);
          }
          if (!isSp()) {
            itemHeight(_this);
            swiperGlCol8[i].update();
            swiperGlCol8[i].navigation.update();
          }
        }
      });
    }
  });


  $.goodslistCol2();
  $.goodslistCol4();
  $.goodslistCol5();
  $.goodslistCol6();
  $.goodslistCol8();
  $('.m-goodslist').goodslist();






  // もっと見る開閉
  // ajax処理によってDOM全体が置き換わるため、documentにイベントを設定
  $(document).on('click', '.m-goodslist_more .m-btn', function(e) {
    const $gl = $(this).closest('.m-goodslist');
    const $textMore = $(this).find('.m-goodslist_textMore');
    const $textClose = $(this).find('.m-goodslist_textClose');
    $hidden = setHiddenArea($gl);

    e.preventDefault();
    let status = $(this).attr('aria-expanded');
    if (status === 'false') {
      scrT = $(window).scrollTop();
      $(this).attr('aria-expanded', true);
      $hidden.show();
      itemHeight($gl);
      $textMore.hide();
      $textClose.show();
    } else {
      $(this).attr('aria-expanded', false);
      $hidden.hide();
      $textMore.show();
      $textClose.hide();
      $(window).scrollTop(scrT);
    }
  });



  // もっと見る 非表示アイテム
  function setHiddenArea(gl) {
    const $gl = gl;
    // SP
    if (isSp()) {
      if ($gl.attr('data-more-sp')) {
        const num = $gl.attr('data-more-sp');
        if (num === 'all') {
          $hidden = 0;
        } else {
          $hidden = $gl.find('.m-goodslist_item:gt(' + (num - 1) + ')');
        }
      }
      // 表示切り替えあり
      // else if ($gl.attr('data-view')) {
      // else if ($gl.attr('[data-view="normal"]') || $gl.attr('[data-view="list"]')) {
      else if ($gl.attr('data-view') === 'normal' || $gl.attr('data-view') === 'list' || $gl.hasClass('m-goodslist-favorite')) {
        $hidden = false;
      }
      else {
        $hidden = $gl.find('.m-goodslist_item:gt(1)');
      }
    }
    // PC
    else {
      if ($gl.attr('data-more-pc')) {
        const num = $gl.attr('data-more-pc');
        $hidden = $gl.find('.m-goodslist_item:gt(' + (num - 1) + ')');
      }
    }
    return $hidden;
  }



  // PC/SP の切り替え時の制御、もっと見るボタンの表示・非表示
  // デフォルトの表示件数以下だった場合は、もっと見るボタンを非表示にする
  function itemMore(_this) {
    const $more = _this.find('.m-goodslist_more');
    const $btn = $more.find('.m-btn');
    const $textMore = $btn.find('.m-goodslist_textMore');
    const $textClose = $btn.find('.m-goodslist_textClose');
    const $item = _this.find('.m-goodslist_item');
    const itemNum = $item.length;
    const numPc = _this.attr('data-more-pc');
    const numSp = _this.attr('data-more-sp');
    $hidden = setHiddenArea(_this);

    if (isSp()) {
      $btn.attr('aria-expanded', false);
      if ($hidden) $hidden.hide();
      $textMore.show();
      $textClose.hide();
      if (numSp) {
        if (itemNum <= numSp) {
          $more.hide();
        } else {
          $more.show();
        }
      }
    }
    else {
      if (_this.hasClass('m-goodslist-more')) {
        $btn.attr('aria-expanded', false);
        $item.show();
        if ($hidden) $hidden.hide();
        $textMore.show();
        $textClose.hide();
      } else {
        $item.show();
      }
      if (numPc) {
        if (itemNum <= numPc) {
          $more.hide();
        } else {
          $more.show();
        }
      }
    }
  }



  // 商品リストの高さ揃え
  function itemHeight(_this) {
    const col = parseInt(_this.attr('data-col'));
    const $item = _this.find('.m-goodslist_item');
    const $body = $item.find('.m-goodslist_body');
    const $color = $item.find('.m-goodslist_color');
    const $colorlist = $color.find('.m-goodsColor');

    $colorlist.each(function () {
      const colorMax = parseInt(_this.attr('data-colormax'));
      const $tile = $(this).find('li');
      let tileW;

      // カラータイルの表示数制御（for IE11）
      $tile.show();
      if (isSp()) {
        $(this).find('li:gt(4)').hide();
      }
      else if ($tile.length > colorMax) {
        $(this).find('li:gt(' + (colorMax - 1) + ')').hide();
      }

      // カラータイルの高さ設定
      tileW = $tile.width();
      $(this).find('ul').css('height', tileW);
    });



    // カラーエリアと .m-goodslist_body の高さ揃え
    if (isSp() || _this.is('[data-view="sp"]') || _this.is('[data-view="list"]')) {
      $item.each(function() {
        const $img = $(this).find('.m-goodslist_img');
        const $color = $(this).find('.m-goodslist_color');
        const $tile =$color.find('.m-goodsColor li');
        $color.css('height', 'auto');
        $body.css('height', 'auto');
        if ($tile.length) {
          let minH = $img.height() + $color.height() + 10;
          $(this).find('.m-goodslist_itemInner').css('min-height', minH);
        }
      });
    }
    else {
      // カラム行ごとのカラーエリア高さ揃え処理
      $color.css('height', 'auto');
      $color.each(function (i) {
        let colorH;

        if (i === 0 || i % col === 0) {
          colorH = $(this).height();

          for (let n = i + 1; n < i + col; n++) {
            if (colorH < $color.eq(n).height()) {
              colorH = $color.eq(n).height();
            }
          }

          for (let n = i; n < i + col; n++) {
            $color.eq(n).css('height', colorH);
          }
        }
      });

      // m-goodslist_body の高さ揃え
      $body.css('height', 'auto');
      $body.each(function (i) {
        let bodyH;

        if (i === 0 || i % col === 0) {
          bodyH = $(this).height();

          for (let n = i + 1; n < i + col; n++) {
            if (bodyH < $body.eq(n).height()) {
              bodyH = $body.eq(n).height();
            }
          }

          for (let n = i; n < i + col; n++) {
            $body.eq(n).css('height', bodyH);
          }
        }
      });
    }
  }



  // リスト表示の場合、価格の横幅を揃える
  function priceWidth(_this) {
    const $prices = _this.find('.m-goodslist_price');

    if (_this.attr('data-view') === 'list') {
      let max = 0;
      $prices.css('width', 'auto');
      $prices.each(function() {
        if ($(this).width() > max) {
          max = $(this).width();
        }
      });
      $prices.width(max);
    }
    else {
      $prices.width('auto');
    }
  };



})();
