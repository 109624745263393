import isSp from './is_sp.js';

export default (() => {

  // もっと見る
  // 表示領域が指定の高さ以上の場合はもっと見るボタンを表示する。以下の場合は非表示にする。
  $.fn.moreHeight = function() {
    $(this).each(function() {
      const $more = $(this);
      const $tgt = $('#' + $more.attr('aria-controls'));
      const tgtH = $tgt.height();
      const dispH = $tgt.attr('data-height');
      const dispHsp = $tgt.attr('data-heightSp');

      if (isSp()) {
        // SP
        if (tgtH <= dispHsp) {
          $more.hide();
          $tgt.css('height', 'auto').attr('aria-hidden', false);
        } else {
          $more.show();
          $tgt.css('height', dispHsp).attr('aria-hidden', true);
        }
      } else {
        // PC
        if (tgtH <= dispH) {
          $more.hide();
          $tgt.css('height', 'auto').attr('aria-hidden', false);
        } else {
          $more.show();
          $tgt.css('height', dispH).attr('aria-hidden', true);
        }
      }
    });
  }

  $('.js-moreHeight').moreHeight();

})();
