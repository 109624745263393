export default (() => {

  // tooltip
  $('.js-tooltip').on('click', function(e){
    e.preventDefault();
    const ACTIVE = 'is-active';
    const $tooltip = $(this);
    const $cnt = $(this).find('.m-tooltip_cnt');

    const mainPad = 20; // .m-mainエリアの左右padding
    const mainL = $('.m-main').offset().left + mainPad;
    const mainW = $('.m-main').width();
    let tooltipW = 0;
    let tooltipL = 0;

    if(!$tooltip.hasClass(ACTIVE)) {
      $('.js-tooltip').removeClass(ACTIVE);
      $tooltip.addClass(ACTIVE);
      tooltipW = $cnt.outerWidth();
      tooltipL = $cnt.offset().left;
      adjustPosition();
      setTimeout(function(){
        $tooltip.attr('aria-hidden', false);
      }, 100);
    } else {
      $tooltip.removeAttr('aria-hidden');
      setTimeout(function(){
        $tooltip.removeClass(ACTIVE);
        $cnt.css('margin-left', 0);
      }, 100);
    }

    // フォームエレメントにフォーカスしたら、ツールチップを閉じる
    $tooltip.closest('.m-main').find('input, textarea, select').on('focus', function() {
      $tooltip.removeClass(ACTIVE);
    });


    // tooltipの位置調整
    function adjustPosition() {
      const overL = mainL  - tooltipL;
      const overR = (mainL + mainW) - (tooltipL + tooltipW);

      // 左にはみ出している場合
      if (tooltipL < mainL) {
        $cnt.css('margin-left', overL);
      }
      // 右にはみ出している場合
      if ((mainL + mainW) < (tooltipL + tooltipW)) {
        $cnt.css('margin-left', overR);
      }
    }

  });

})();
