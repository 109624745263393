// init form
export default ($tgt) => {

  if ($tgt.prop('tagName') === 'SELECT') {
    $tgt.find('option').prop('selected', false);
  }
  else if ($tgt.is(':text') || $tgt.prop('tagName') === 'TEXTAREA') {
    $tgt.val('');
    if ($tgt.attr('class') === 'm-otherDetail') {
      $tgt.prop('disabled', true);
    }
  }
  else if ($tgt.is(':radio') || $tgt.is(':checkbox')) {
    $tgt.prop('checked', false);
  }

  // 連動制御項目の初期化
  if ($tgt.hasClass('js-linkedRequired_trigger')) {
    const $item = $tgt.closest('.js-linkedRequired').find('.js-linkedRequired_item');
    $item.each(function() {
      const $required = $(this).closest('tr').find('.m-formTtl_required');
      const $errMsg = $('#' + $(this).attr('aria-describedby')).find('[data-error], .errorMsg');

      $(this).prop('disabled', true).removeAttr('aria-invalid').val('');
      $(this).closest('.m-validation').removeAttr('data-valid required aria-required');
      $errMsg.hide();
      $required.addClass('g-hidden');
    });
  }

};




