// sp judge
export default () => {

  let width = window.innerWidth ? window.innerWidth : $(window).width();

  if (width <= 768) {
    return true;
  } else {
    return false;
  }

};
