import isSp from './is_sp.js';

export default (() => {

  // goodslist color
  $('.m-goodsColor').each(function() {
    const _this = $(this);
    const $parent = _this.closest('.m-goodslist');
    const $tile = _this.find('li');
    const colorMax = $parent.attr('data-colormax');
    let tileW;


    // ギャラリーモーダル内のカラータイルの場合
    $('.js-modal[aria-controls="modalGallery"]').on('click', function() {
      const $goodsC = $('#modalGallery .m-goodsColor');
      setTimeout(function() {
        // カラータイルの表示数制御（for IE11）
        $tile.show();
        if ($tile.length > colorMax) {
          $goodsC.find('li:gt(' + (colorMax - 1) + ')').hide();
        }
        // カラータイルの高さ設定
        tileW = $tile.width();
        $goodsC.find('ul').css('height', tileW);
      }, 100);
    });


    $(window).on('load resize', function () {
      ellipsis(_this);
    });

    $('.m-controlbar_view input').on('change', function() {
      ellipsis(_this);
    });


    // カラータイル数による省略記号の表示制御
    function ellipsis(_this) {
      if (isSp() && !_this.is('[data-colormax]')) {
        if ($tile.length > 5) {
          _this.attr('data-ellipsis', '…');
        } else {
          _this.attr('data-ellipsis', '');
        }
      }
      else {
        if ($tile.length > colorMax) {
          _this.attr('data-ellipsis', '…');
        } else {
          _this.attr('data-ellipsis', '');
        }
      }
    }
  });


})();
